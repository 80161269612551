body {
  /* background-color: #2C2E2E; */
}

.auth-layout {
  height: 100vh;
  margin: 0;
  width: 100%;
  padding: 10px;
  font-size: 20px;
}

@media (max-height: 827px) {
  .auth-layout {
    height: 100%;
  }
}