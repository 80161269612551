.client-container {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (min-width: 1280px){
  .client-container {
    max-width: 1500px;
  }
}

.paper {
  padding: 7px;
  display: 'flex';
  overflow: 'auto';
  flex-direction: 'column';
  border-radius: 95px  0px 0px 95px !important;
  width: 524px;
  background: #DBDCDC 0% 0% no-repeat padding-box !important;
  /* height: 75px; */
}
.fixedHeight {
  height: 68px;
}