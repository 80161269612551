.client-container {
  padding-top: 32px;
  padding-bottom: 32px;
}
@media (min-width: 1280px){
  .client-container {
    max-width: 1500px;
  }
}

.paper {
  padding: 10px;
  display: 'flex';
  overflow: 'auto';
  flex-direction: 'column';
}
.fixedHeight {
  height: 240;
}

.toolbar{
  padding-left: 0px !important;
}